.ant-table-tbody {
    /* background-color: rebeccapurple; */
    /* color: white; */
    color: #d6b8f5;
}

.ant-table-tbody .ant-table-row:nth-child(even) {
    background-color: #001529;
  }
.ant-table-tbody .ant-table-row:nth-child(odd) {
    background-color: #051d33
    
  }

  .ant-table-row:hover{
    color: rgb(0, 0, 0);
  }

  #root > div > section > section > main > div > div > div > div > div > div > div > div > div > div > table > thead > tr > th:nth-child(odd){
    background-color: #001529;
    color: white;
  }
  #root > div > section > section > main > div > div > div > div > div > div > div > div > div > div > table > thead > tr > th:nth-child(even){
    background-color: #001529;
    color: white;
  }

  /* pagination text color  */
  #root > div > section > section > main > div > div > div > div > div > div > ul > li{
    background-color: aliceblue;
  }

  /* #root > div > section > section > main > div > div > div > div > div > div > ul > li.ant-pagination-prev{
    background-color: aliceblue;
  }
  #root > div > section > section > main > div > div > div > div > div > div > div > ul > li.ant-pagination-next{
    background-color: aliceblue;
  } */
  /* #root > div > section > section > main > div > div > div > div > div > div > ul > li.ant-pagination-prev */